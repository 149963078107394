import React, { useContext, useEffect, useState } from 'react';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Button, Alert } from 'react-bootstrap';
import Wrapper from './components/Wrapper';

function ConfirmAppt() {
  const { job } = useContext(WizardContext);
  const navigate = useNavigate();
  const [decision, setDecision] = useState('');
  const [rescheduleConfirm, setRescheduleConfirm] = useState(false);
  const [reason, setReason] = useState('');
  const [decided, setDecided] = useState(false);

  useEffect(() => {
    // console.log('template status', job?.template?.status);
    console.log('job', job);
    if (job.id && (!job.tileZip || job?.template?.status === 'Templated')) {
      navigate('../');
    }
  }, [job.id, job?.template?.status, job?.tileZip]);

  return decided ? (
    <Wrapper>
      <div className="container">
        <h5>Arrival Window Confirmed</h5>
        <p>Awesome! We can't wait to see you! </p>
        <p>
          To make sure everything goes smoothly, check out this little video for
          more information on how to be ready: Video link
        </p>
        <p>Quick Checklist to be ready:</p>
        <ul>
          <li>Sink, faucet, & range on-site</li>
          <li>
            New cabinets fully installed including all end panels (or existing
            cabinets cleared off)
          </li>
          <li>Have an undermount farm sink?</li>
          <ul>
            <li>
              Have cabinets modified and sink in its final resting place, not
              plumbed.
            </li>
          </ul>
          <li>Decision-maker (18+) on-site for the entire appointment</li>
        </ul>
        <Alert variant="secondary">
          Note: If we’re unable to template due to unpreparedness, there will be
          an "unable to template" fee.
        </Alert>
        <p>See you soon! Let us know if you have any questions.</p>
      </div>
    </Wrapper>
  ) : (
    <Wrapper>
      <div className="container">
        <h5>Confirm Arrival Window</h5>

        <Row>
          <p>
            We are ready to get started! Let’s confirm your TEMPLATE appointment
            is scheduled for {job?.template?.dateFormatted} with our team set to
            arrive between{' '}
          </p>
          <p>Click your choice below to move forward with your project:</p>
        </Row>

        <Form className="outdoor-field">
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="confirm"
              label={`Perfect - see you there`}
              checked={decision === 'confirm'}
              onChange={() => {
                setDecision('confirm');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="reschedule"
              id="yes"
              label={<>I need to reschedule</>}
              checked={decision === 'reschedule'}
              onChange={() => {
                setDecision('reschedule');
              }}
            />
          </Row>
        </Form>
        {decision === 'reschedule' && (
          <>
            <p>Are you sure you need to reschedule?</p>
            <p>
              Just a heads up - if we reschedule, we'll have to find the next
              available date. Let us know what works best for you!
            </p>
            <Form>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="textOK"
                  label="Yes, please contact me to reschedule"
                  checked={rescheduleConfirm}
                  onChange={({ target }) =>
                    setRescheduleConfirm(target.checked)
                  }
                  inline
                />
              </Form.Group>
              {rescheduleConfirm && (
                <>
                  <p className="fs--1" style={{ fontStyle: 'italic' }}>
                    Are there any scheduling concerns we should be aware of
                    while we look for a new date for you?{' '}
                  </p>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      bssize="sm"
                      id="experienceDescription"
                      value={reason}
                      onChange={({ target }) => setReason(target.value)}
                      rows={5}
                      className="mb-3"
                    />
                  </Form.Group>
                </>
              )}
            </Form>
          </>
        )}
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={
            !decision ||
            (decision === 'reschedule' && (!rescheduleConfirm || !reason))
          }
          onClick={() => {
            // handleSplashes(job.rooms, decision, true, finish); // true means postOV
            if (decision === 'confirm') {
              setDecided(true);
            } else {
              navigate('../');
            }
          }}
        >
          Submit My Selection
        </Button>
      </div>
    </Wrapper>
  );
}

export default ConfirmAppt;
